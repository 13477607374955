.footer-container {
    padding: 30px;
    background-color: red;
}

.footer-novartis-logo {
    height: 20px;
    width: auto;
}

.footer-item {
    margin: 0 20px;
    color: var(--color-slate);
}

.footer-disclaimer {
    font-family: "Caecilia";
    font-size: 16px;
    color: var(--color-slate);
}

@media screen and (max-width: 767px) {
    .footer-novartis-logo {
        height: 18px;
        width: auto;
    }

    .footer-item {
        margin: 5px;
        font-size: 10px;
    }
}