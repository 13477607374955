.sponsors-container {
    padding: 10px;
    border-top: 1px solid #eee;
    background-color: #f7f7f7;
    /*flex-direction: column;*/
    justify-content: center;
}

.sponsors-label {
    font-size: 14px;
    font-stretch: 100%;
    font-style: italic;
    font-weight: 200;
    color: #aab4ba;
    margin: 0 0 5px 5px;
}

.sponsor-img-wrapper {
    margin-right: 20px;
}

.sponsor-img {
    max-height: 20px;
}

.sponsors-logos {
    margin-right: 10px;
    align-items: center;
}

@media only screen and (min-width: 769px) {
    .sponsors-logos {
        margin-left: 30px;
    }

    .sponsors-container {
        padding: 15px;
        flex-direction: row;
        justify-content: flex-start;
    }

    .sponsors-label {
        font-size: 14px;
        font-stretch: 100%;
        font-style: italic;
        font-weight: 200;
        color: #aab4ba;
        margin: 0;
    }

    .sponsor-img-wrapper {
        margin-right: 40px;
    }

    .sponsor-img {
        max-height: 20px;
    }
}