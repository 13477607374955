:root {
  /* Uncomment to override Locator Core */
  --color-primary: #6667ab;
  --color-secondary: #86a1a9;
}

/* oswald-200 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/oswald-v13-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Oswald ExtraLight'), local('Oswald-ExtraLight'),
  url('./fonts/oswald-v13-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/oswald-v13-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/oswald-v13-latin-200.woff') format('woff'), /* Modern Browsers */
  url('./fonts/oswald-v13-latin-200.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/oswald-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Oswald Regular'), local('Oswald-Regular'),
  url('./fonts/oswald-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/oswald-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/oswald-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/oswald-v13-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-500 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/oswald-v13-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Oswald Medium'), local('Oswald-Medium'),
  url('./fonts/oswald-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/oswald-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/oswald-v13-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./fonts/oswald-v13-latin-500.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-300 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/oswald-v13-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Oswald Light'), local('Oswald-Light'),
  url('./fonts/oswald-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/oswald-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/oswald-v13-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./fonts/oswald-v13-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-600 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/oswald-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Oswald SemiBold'), local('Oswald-SemiBold'),
  url('./fonts/oswald-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/oswald-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/oswald-v13-latin-600.woff') format('woff'), /* Modern Browsers */
  url('./fonts/oswald-v13-latin-600.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/oswald-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Oswald Bold'), local('Oswald-Bold'),
  url('./fonts/oswald-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/oswald-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/oswald-v13-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./fonts/oswald-v13-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: bold;
  font-weight: 500;
  src: url('./fonts/lato/Lato-Bol.otf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/lato/Lato-Reg.otf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/lato/Lato-Lig.otf');
}

.headlineFont {
  font-family: "Oswald";
}

.bodyFont {
  font-family: "Lato";
}

.colorPrimary {
  color: var(--color-primary);
}

.oswald {
  font-family: Oswald;
}

.lat {
  font-family: Lato;
}

.contact-info-container {
  background-color: var(--color-primary);
}

.medications-available-container {
  /*background-color: var(--color-secondary);*/
}

.details-green-bg {
  /*background-color: var(--color-secondary);*/
}

.details-blue-bg, .quick-facts-container, .details-description {
  background-color: var(--color-primary);
}

.quick-facts-label {
  color: var(--color-secondary);
  text-transform: uppercase;
  font-weight: 600;
}

.quick-facts-value {
  /*font-family: "FFMark";*/
  /*font-weight: 500;*/
}

.amenities-header {
  color: var(--color-primary);
}

.business-hours-empty {
  /*font-family: "FFMark";*/
  color: var(--color-secondary);
}

.business-hours-day {
  font-family: "FFMark";
  font-weight: 500;
  color: var(--color-secondary);
  text-transform: uppercase;
}

.business-hours-value {
  color: var(--color-secondary);
}

.business-hours-header {
  font-family: "FFMark";
  font-weight: 300;
  color: var(--color-primary);
}